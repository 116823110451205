// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,700;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Teko:wght@700&display=swap");

$primary: #37b6ab; // Thats a sample how you could change a BootStrap variable.
$blunt-green: $primary;
$gold: #a0a082;
$gray: #c4c4c4;
$light-gray: #f3f3f3;
$dark-grey: #222222;
$black: #000000;
$white: #ffffff;
$secondary: $black;

$navbar-nav-link-padding-x: 1.2rem;
$navbar-dark-color: $white;
$navbar-dark-hover-color: $blunt-green;
$enable-rounded: false;

$Inter: "Inter", sans-serif;
$Teko: "Teko", sans-serif;
$Plex: "IBM Plex Sans", sans-serif;
$grid-gutter-width: 20px;
