// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here

.bg-black {
	background: $black;
}

body {
	transition: margin 0.8s;
	max-width: 100%;
}

.btn {
	font-family: $Teko;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;
}

.btn-trans {
	border: 0px solid transparent;
	background-color: transparent;
}

.btn-outline-secondary {
	border: 4px solid $secondary;
}
.btn-outline-white {
	border: 4px solid $white;
	color: $white;
}

.bg-darkgray {
	background-color: $dark-grey;
}
.bg-lightgray {
	background-color: $light-gray;
}

.text-gold {
	color: $gold;
}

.spacer {
	@extend .w-100;
	&.fifty {
		height: 50px;

		@include media-breakpoint-down(md) {
			height: 25px;
		}
	}
	&.eighty {
		height: 80px;
		@include media-breakpoint-down(md) {
			height: 40px;
		}
	}
	&.onehundy {
		height: 100px;
		@include media-breakpoint-down(md) {
			height: 50px;
		}
	}
	&.onefifty {
		height: 150px;
		@include media-breakpoint-down(md) {
			height: 70px;
		}
	}
}

// popup menu

#popupMenu {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.5s linear;
	// display: none;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 12;
	background-color: $black;
	position: fixed;
	@media only screen and (max-device-height: 728px) {
		overflow-y: scroll;
	}
}

body {
	width: 100vw;
	&.display-popup {
		#popupMenu {
			// display: block;
			visibility: visible;
			opacity: 1;
		}
	}
}

// Ads

.a-d {
	p {
		color: $gold;
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 10px !important;
		line-height: 105.4% !important;
		margin-bottom: 12px !important;
	}
}

// Related Items

#realtedItems {
	@include media-breakpoint-down(md) {
		position: fixed;
		width: 100vw;
		height: 100vh;
		left: -100vw;
		top: 0;
		right: 0;
	}
	transition: left 0.8s;
	background-color: $dark-grey;
	color: white;
}

body {
	width: 100vw;
	overflow-x: hidden;
	&.show-related {
		@include media-breakpoint-down(md) {
			margin-left: 80vw;
			margin-right: -80vw;
		}
		// Related Items
		#realtedItems {
			left: -20vw;
		}
	}
}
.site {
	min-height: 101vh;
}
// Nav bar

body.scrolled {
	padding-top: 48px;
	#wrapper-navbar {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 10;
	}
	&.admin-bar {
		#wrapper-navbar {
			top: 32px;
		}
	}
}
.nav-padding {
	background-color: $black;
	width: 100%;
	padding-top: 12px;
	@include media-breakpoint-up(md) {
		padding-top: 52px;
	}
}
#wrapper-navbar {
	background: $black;
	// padding-top: 12px;
	// padding-bottom: 12px;
	// @include media-breakpoint-up(md) {
	// 	padding-top: 52px;
	// 	padding-bottom: 42px;
	// }
	.header-container {
		position: relative;
		.toggle-menu {
			background-color: transparent;
			border: 0px solid transparent;
		}
	}
	#main-nav {
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
		li {
			&.menu-item {
				a {
					@include media-breakpoint-between(md, lg) {
						font-size: 14px;
						padding-right: 0.7rem;
						padding-left: 0.8rem;
					}
					font-family: $Inter;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 105.4%;
					position: relative;
				}
			}
			&.active,
			&:hover {
				a {
					color: $blunt-green;
					&:before {
						position: absolute;
						top: 100%;
						// left: $spacer;
						// right: $spacer;

						@include media-breakpoint-up(md) {
							left: 0px;
							right: 0px;
						}
						@include media-breakpoint-up(lg) {
							left: $spacer / 2;
							right: $spacer / 2;
						}
						@include media-breakpoint-up(xl) {
							left: $spacer;
							right: $spacer;
						}
						content: " ";
						height: 3px;
						background: rgb(55, 182, 171);
						background: linear-gradient(
							90deg,
							rgba(55, 182, 171, 1) 0%,
							rgba(255, 255, 255, 0.8015581232492998) 100%
						);
					}
				}
			}
		}
	}
	.site-branding {
		img {
			@include media-breakpoint-down(md) {
				max-width: 95px;
				height: auto;
			}
		}
	}
	.enable-related {
		background: none !important;
		outline: none;
		border: none;
	}
}

#navbarMobile {
	a {
		font-family: $Inter;
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 26px;
		color: $white;
		&:hover {
			color: $blunt-green;
		}
	}
	li {
		@extend.mb-1;
		&.active {
			a {
				color: $blunt-green;
			}
		}
	}
}

#navbarMobileDub {
	a {
		color: white;
		&:hover {
			color: $blunt-green;
		}
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 49px;
	}
}

// Footer

#wrapper-footer {
	@extend .pt-5;
	@extend .pb-2;
	background-color: $dark-grey;
	color: $white;
	.footer-menu-wrapper {
		.nav {
			li {
				&.menu-item {
					.nav-link {
						padding: 0 0 14px 0;
						color: $white !important;
						font-family: $Inter;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 105.4%;
					}
				}
			}
		}
	}
	.social-links {
		li {
			a {
				&:first-of-type {
					padding-left: 0;
				}
				i {
					font-size: 30px;
				}
				color: white;
			}
		}
	}
	h5 {
		font-family: $Teko;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 1.2em;
	}
	// .join-blunt-mag {
	// 	input[type="text"] {
	// 		background-color: transparent;
	// 		border-bottom: 4px solid $light-gray;
	// 		border-left: 0;
	// 		border-right: 0;
	// 		border-top: 0;
	// 		margin-right: 20px;
	// 		padding-left: 0;
	// 		color: $light-gray;
	// 	}
	// 	.input-group-append {
	// 		button {
	// 			border: 4px solid $white;
	// 			background-color: transparent;
	// 			color: white;
	// 			font-family: $Teko;
	// 			font-style: normal;
	// 			font-weight: bold;
	// 			font-size: 18px;
	// 			line-height: 1em;
	// 		}
	// 	}
	// }
	.bottom-row {
		.right {
			p {
				font-family: $Inter;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 130%;
			}
			img {
				width: 34px;
				height: 23px;
				margin-right: 15px;
			}
		}
		.left {
			p {
				font-family: $Inter;
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 105.4%;
			}
		}
	}
}
.sub-footer {
	border-top: 1px solid #535353;
	p {
		margin-bottom: 0;
		color: white;
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 105.4%;
	}
	a {
		&.nav-link {
			color: white !important;
			font-family: $Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 105.4%;
		}
	}
}

/// article

.the-content {
	@include media-breakpoint-up(md) {
		padding-right: 46px;
	}
	@include media-breakpoint-up(lg) {
		padding-right: 96px;
	}
	p {
		font-size: 16px;
		&:first-of-type {
			&:first-letter {
				-webkit-initial-letter: 2;
				initial-letter: 2;
				font-weight: 700;
				float: left;
				font-size: 400%;
				margin-top: 18px;
				margin-right: 15px;
			}
		}
		&.cap {
			&:first-letter {
				-webkit-initial-letter: 2;
				initial-letter: 2;
				font-weight: 700;
				float: left;
				font-size: 400%;
				margin-top: 18px;
				margin-right: 15px;
			}
		}
	}
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 30px;
		line-height: 45px;
		margin-bottom: 30px;
		@include media-breakpoint-up(md) {
			margin-bottom: 40px;
		}
	}
	h1 {
		font-size: 40px;
		line-height: 54px;
	}
	h2 {
		font-size: 34px;
		line-height: 54px;
	}
	h3 {
		font-size: 30px;
		line-height: 45px;
	}
	h4 {
		font-size: 26px;
		line-height: 40px;
	}
	// .iframe-container {
	// 	overflow: hidden;
	// 	padding-top: 56.25%;
	// 	position: relative;
	// }
	// .iframe-container iframe {
	// 	border: 0;
	// 	height: 100%;
	// 	left: 0;
	// 	position: absolute;
	// 	top: 0;
	// 	width: 100%;
	// }
}
.header-wrapper-bg {
	background-color: black;
	// padding-top: 7 * $spacer;
	// @include media-breakpoint-up(md) {
	// padding-top: 4 * $spacer;
	// }
}
.header-wrapper {
	@include media-breakpoint-down(md) {
		padding-left: 0px;
		padding-right: 0px;
		margin-left: 0;
		margin-right: 0;
	}
	.entry-header {
		position: relative;
		background-color: $black;
		margin-bottom: 50px;
		@include media-breakpoint-up(md) {
			margin-bottom: 80px;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 180px;
		}
		z-index: 1;
		.image-bg {
			position: relative;
			margin-left: 26px;
			margin-top: 72px;
			margin-bottom: -25px;
			z-index: 2;
			@include media-breakpoint-up(md) {
				margin-top: 0px;
				margin-left: 18%;
				margin-bottom: -55px;
			}
			@include media-breakpoint-up(lg) {
				margin-top: 0px;
				margin-left: 28%;
				margin-bottom: -125px;
			}
			img {
				width: 100%;
				height: auto;
			}
			.gradient {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgb(0, 0, 0);
				background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.8015581232492998) 0%,
					rgba(0, 0, 0, 0) 28%
				);
			}
		}
		.header-details {
			z-index: 3;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			h1 {
				color: white;
				font-family: $Plex;
				font-style: normal;
				font-weight: bold;

				@media only screen and (max-width: 320px) {
					font-size: 20px;
					line-height: 20px;
				}
				font-size: 35px;
				line-height: 35px;
				text-shadow: 1px 1px 1px #000;
				@include media-breakpoint-up(md) {
					font-family: $Plex;
					font-style: normal;
					font-weight: bold;
					font-size: 45px;
					line-height: 45px;
				}
				@include media-breakpoint-up(xl) {
					font-family: $Plex;
					font-style: normal;
					font-weight: bold;
					font-size: 65px;
					line-height: 65px;
				}
			}
			h5 {
				color: $blunt-green;
				font-family: $Teko;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 120%;
			}
		}
		.header-footer-details {
			z-index: 3;
			position: absolute;
			left: 0;
			bottom: 16px;
			right: 0;
			color: white;
			a {
				color: white;
			}
		}
		.cat-links {
			color: $gold;
			font-family: $Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 20px;
			a {
				color: $gold;
			}
		}
	}
}

.twin_images {
	img {
		margin-bottom: 10px;
	}
	p.title {
		font-family: $Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 12px !important;
		line-height: 20px;
		color: $black;
		@extend .mb-0;
	}
	figcaption {
		font-family: $Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 20px;
		color: $gold;
		display: block !important;
	}
}

p.title {
	font-family: $Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 12px !important;
	line-height: 20px;
	color: $black;
	@extend .mb-0;
}
figcaption {
	font-family: $Inter;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	color: $gold;
	width: 100%;
	display: block !important;
}

.tags-links {
	margin-top: 60px;
	margin-bottom: 60px;
	font-family: $Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 105.4%;
	a {
		color: $gold;
		margin-right: 18px;
		text-transform: uppercase;
		&:first-of-type {
			margin-left: 20px;
		}
	}
}
.related-content {
	h5 {
		font-family: $Teko;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 120%;
	}
}
.entry-content {
	p {
		font-family: $Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 35px;
	}
}
.more-div {
	.more-heading {
		position: relative;
		h4 {
			position: relative;
			padding-left: 15px;
			padding-right: 15px;
			@include media-breakpoint-up(md) {
				padding-left: 55px;
				padding-right: 55px;
			}
			display: table;
			text-align: center;
			margin: 0 auto;
			background-color: white;
			z-index: 2;
			font-family: $Teko;
			font-style: normal;
			font-weight: bold;
			font-size: 36px;
			line-height: 120%;
		}
		&:before {
			content: "";
			width: 100%;
			border-bottom: solid 1px $gray;
			position: absolute;
			left: 0;
			top: 48%;
			z-index: 1;
		}
	}
}
.article-sharing {
	.circle-icon {
		text-align: center;
		background: $black;
		border-radius: 50%;
		width: 45px;
		height: 45px;
		line-height: 45px;
		vertical-align: middle;
		border-radius: 50%;
	}
	i {
		color: white;
		font-size: 22px;
	}
	a {
		color: white;
	}
}

// Homepage

.home-page-slider-wrapper {
	position: relative;
	.manual-stepper {
		z-index: 2;
		position: absolute;
		left: 0;
		bottom: 0;
		top: 0;
		width: 400px;
		display: flex;
		align-self: flex-start;
		align-items: center;
		justify-content: center;
		.step {
			opacity: 0.6;
			&.active {
				opacity: 1;
			}
			min-height: 160px;
			h2 {
				color: white;
				font-family: $Plex;
				font-style: normal;
				font-weight: bold;
				font-size: 30px;
				line-height: 35px;
				a {
					color: white;
					&:hover {
						text-decoration: none;
					}
				}
			}
			.primary-cat {
				color: $blunt-green;
				font-family: $Teko;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 120%;
				text-transform: uppercase;
			}
		}
	}
}

#homepageCarousel {
	overflow: visible;
	.home-image {
		width: 100%;
		height: auto;
		visibility: hidden;
		// margin-left: 50;
		// margin-right: 50px;
		// margin-top: 50px;
		// margin-bottom: 50px;
	}
	.swiper-slide {
		overflow: visible !important;
		&.swiper-slide-active {
			.home-image {
				box-shadow: 4px 4px 50px 20px #37b6ab;
				visibility: visible;
			}

			.slider-meta {
				opacity: 1 !important;
			}
			.gradient {
				opacity: 1 !important;
			}
		}
		.image-wrapper {
			position: relative;

			.gradient {
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgb(0, 0, 0);
				background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.8015581232492998) 0%,
					rgba(0, 0, 0, 0) 18%
				);
			}
			.slider-meta {
				z-index: 3;
				opacity: 0;
				top: calc(100% - 40px);
				left: 10px;
				right: 35%;
				position: absolute;
				@extend .d-lg-none;
				h5 {
					color: $blunt-green;
					font-family: $Teko;
					font-style: normal;
					font-weight: bold;
					font-size: 18px;
					line-height: 120%;
					margin-bottom: 0;
				}
				.entry-title {
					text-shadow: 1px 1px 1px #000;
					font-family: $Plex;
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 26px;
					a {
						color: $white;
					}
					@media only screen and (max-width: 320px) {
						font-size: 20px;
						line-height: 22px;
					}

					@include media-breakpoint-up(md) {
						font-size: 28px;
						line-height: 32px;
					}
				}
			}
		}
	}
}

.home-page-leader {
	// margin-bottom: -120px;
	position: relative;
	.white-bottom {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		height: 30px;
		background-color: white;
		@extend .d-none;
		@extend .d-lg-block;
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 155px;
	}
	.swiper-button-next {
		top: calc(100% + 35px);
		right: 10px;
		&:after {
			font-size: 28px;
		}
	}
	.swiper-button-prev {
		top: calc(100% + 35px);
		right: 80px;
		left: auto;
		&:after {
			font-size: 28px;
		}
	}
}

.front-videos {
	.primary-box {
		@include media-breakpoint-up(lg) {
			position: relative;
		}
	}
	.image-div {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: 690px;
			max-width: 100%;
		}
		img {
			width: 100%;
			height: auto;
		}
		z-index: 2;
	}
	.entry-meta {
		position: relative;
		z-index: 3;
		background-color: white;
		margin-top: -30px;
		margin-left: 20px;
		margin-right: 20px;
		@include media-breakpoint-up(lg) {
			margin-top: 0px;
			margin-left: 0px;
			margin-right: 0px;
			top: 45px;
			bottom: 45px;
			right: 20px;
			width: 50%;
			position: absolute;
		}

		@extend .p-lg-5;
		@extend .p-4;
		a {
			color: $black;
		}
		background: #ffffff;
		box-shadow: 0px 4px 50px 2px #37b6ab;
		.entry-title {
			font-style: normal;
			font-weight: bold;
			font-size: 28px;
			line-height: 32px;
			@include media-breakpoint-up(lg) {
				font-family: $Plex;
				font-style: normal;
				font-weight: bold;
				font-size: 35px;
				line-height: 38px;
			}
		}
		.subtitle {
			font-family: $Teko;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 120%;
		}
	}
	.smaller {
		.subtitle {
			margin-bottom: 30px;
			font-family: $Teko;
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			line-height: 120%;
		}
		.entry-meta-small {
			height: 76px;
			width: 100%;
			.entry-title {
				margin-top: 8px;
				margin-bottom: 8px;
				font-family: $Inter;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				a {
					color: $black;
				}
			}
		}
	}
}

.new-music {
	.tease {
		@extend .d-none;
		@extend .d-lg-block;
	}
	.primary-col {
		.image-div {
			margin: 0px;
			@include media-breakpoint-up(lg) {
				margin: 0 5px 20px 20px;
			}
			position: relative;
			display: flex;
			align-items: end;
			.entry-meta {
				position: absolute;
				left: -12px;
				right: 45px;
				bottom: 0;
				margin-bottom: 10px;
				@include media-breakpoint-up(lg) {
					margin-bottom: 30px;
					right: 30px;
				}
				.subtitle {
					color: $blunt-green;
					font-family: $Teko;
					font-style: normal;
					font-weight: bold;
					@include media-breakpoint-up(lg) {
						font-size: 18px;
						line-height: 120%;
					}
				}
				h2 {
					font-style: normal;
					font-weight: bold;
					font-size: 28px;
					line-height: 32px;
					font-family: $Plex;

					@include media-breakpoint-up(lg) {
						font-style: normal;
						font-weight: bold;
						font-size: 52px;
						line-height: 60px;
					}
					color: $white;
					a {
						color: $white;
					}
				}
			}
			img {
				background: linear-gradient(
						60.26deg,
						#000000 3.86%,
						rgba(0, 0, 0, 0) 59.63%
					),
					url(Fever333.jpg);
				box-shadow: 4px 4px 40px 5px #37b6ab;
			}
		}
	}
}
.join-blunt-mag-front {
	padding-top: 107px;
	padding-bottom: 107px;
	background-color: $dark-grey;
}
.join-blunt-mag-front,
.join-blunt-mag,
.join-blunt-mag-archive {
	color: $white;
	h3 {
		font-family: $Teko;
		font-style: normal;
		font-weight: bold;
		font-size: 42px;
		line-height: 50px;
	}
	.signup-form-custom {
		h4 {
			font-family: $Teko;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 50px;
			color: white;
		}
		input[type="text"] {
			background-color: transparent;
			border-bottom: 4px solid $light-gray;
			border-left: 0;
			border-right: 0;
			border-top: 0;
			margin-right: 20px;
			padding-left: 12px;
			color: $blunt-green;
			&::placeholder {
				/* Chrome, Firefox, Opera, Safari 10.1+ */
				color: $light-gray;
				opacity: 1; /* Firefox */
			}

			&:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: $light-gray;
			}

			&::-ms-input-placeholder {
				/* Microsoft Edge */
				color: $light-gray;
			}
			&:focus {
				border-bottom: 4px solid $blunt-green;
			}
		}
		.input-group-append {
			button {
				border: 4px solid $white;
				background-color: transparent;
				color: white;
				font-family: $Teko;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 1em;
			}
		}
		.error-div {
			display: none;
			color: #ff365a;
			padding-left: 12px;
			font-family: $Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 105.4%;
		}
		&.has-error {
			input {
				color: #ff365a;
				border-bottom: 4px solid #ff365a;
			}
			.error-div {
				display: block;
			}
		}
	}

	p {
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;
		&.join-subtext {
			font-family: $Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 10px;
			line-height: 20px;
		}
	}
}

// TEASE

.tease {
	img {
		width: 100%;
		height: auto;
	}
	&.tease-wide {
		.tile-wrapper {
			border-bottom: 1px solid $gray;
			padding-bottom: 18px;
			margin-bottom: 22px;
			@include media-breakpoint-up(md) {
				padding-right: 10px;
				border-right: 1px solid $gray;
				border-bottom: 0px solid white;
				padding-bottom: 0px;
				margin-bottom: 0px;
			}
		}
		&:last-of-type {
			.tile-wrapper {
				border-right: 0px solid white;
				border-bottom: 1px solid white;
			}
		}
		.entry-header {
			// margin-top: -6px;
		}
		.tile-image {
			width: 132px;
			min-width: 132px;
			img {
				width: 132px;
				height: auto;
			}
		}
		h2 {
			&.entry-title {
				margin: 0;
				font-family: $Plex;
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 22px;
				color: $black;
				a {
					font-family: $Plex;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 22px;
					color: $black;
				}
			}
		}
	}
	&.tease-related {
		padding-bottom: $spacer;
		margin-bottom: $spacer;
		img {
			max-width: 109px;
			height: auto;
		}
		h2 {
			font-family: $Plex;
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			a {
				color: $black;
			}
		}
		.tile-wrapper {
			@include media-breakpoint-up(md) {
				margin-right: 40px;
			}
			border-bottom: 1px solid $gray;
		}
	}
	.cat-links {
		margin-bottom: 8px;
		color: $gold;
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 1em;
		a {
			color: $gold;
		}
	}
	footer {
		color: $gold;
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		a {
			color: $gold;
		}
		span {
			padding-right: 0;
		}
	}
	.article-content {
		min-height: 85px;
		@include media-breakpoint-up(md) {
			min-height: 95px;
		}
	}
	&.tease-tall-long-read {
		img {
			background: linear-gradient(
					0deg,
					rgba(0, 0, 0, 0.13),
					rgba(0, 0, 0, 0.13)
				),
				url(92559395_1570074919840108_7380612487395147776_o-1024x731.jpg);
			box-shadow: 10px 10px 30px 2px #37b6ab;
		}
		.entry-title {
			font-weight: 500;
			font-family: $Plex;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 26px;
			a {
				color: $black;
			}
		}
		.read-length {
			margin-top: 38px;
			font-family: $Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 105.4%;
		}
	}
	&.tease-tall {
		.entry-header {
			.title {
				min-height: 96px;
			}
			.entry-title {
				font-family: $Plex;
				font-style: normal;
				font-weight: 500;
				font-size: 28px;
				line-height: 36px;
				@include media-breakpoint-up(lg) {
					font-size: 20px;
					line-height: 26px;
				}
				color: $black;
				a {
					color: $black;
				}
			}
			.cat-links {
				padding-top: 20px;
				padding-bottom: 10px;
				color: $gold;
				font-family: $Inter;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 120%;
				a {
					color: $gold;
				}
			}
		}
		margin-bottom: 60px;
	}
}

// Archive Pages

.archive-header {
	margin-bottom: 50px;
	padding-top: 20px;
	padding-bottom: 60px;
	background-color: $black;
	.archive-header-container {
		min-height: 250px;
		position: relative;
		.floating-header {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			display: flex;
			justify-content: center;
			.page-header {
				text-align: center;
				h1 {
					font-family: $Teko;
					font-style: normal;
					font-weight: bold;

					font-size: 130px;
					line-height: 120%;
					@include media-breakpoint-up(md) {
						font-size: 220px;
						line-height: 120%;
					}
					color: black;
					text-shadow: 0px 4px 50px rgba(55, 182, 171, 0.9);
					text-transform: uppercase;
				}
			}
		}
		.subtitle {
			font-family: $Teko;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 120%;
		}
		h2 {
			&.entry-title {
				font-family: $Plex;
				font-style: normal;
				font-weight: bold;
				font-size: 30px;
				line-height: 35px;
				color: $white;
				a {
					color: $white;
				}
			}
		}
		.image-div {
			padding-top: 70px;

			@include media-breakpoint-up(lg) {
				padding-top: 130px;
			}
		}
		.entry-meta {
			.cat-links {
				color: $gold;
				font-family: $Inter;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				a {
					color: $gold;
				}
			}
			.byline,
			.posted-on {
				color: $white;
				font-family: $Inter;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				a {
					color: $white;
				}
			}
			.excerpt {
				color: white;
				font-family: $Inter;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 35px;
			}
		}
	}
	&.large-heading {
		h1 {
			margin-top: 30px;
			font-size: 40px !important;
			@include media-breakpoint-up(sm) {
				font-size: 60px !important;
			}
			line-height: 120% !important;
			@include media-breakpoint-up(md) {
				font-size: 110px !important;
				line-height: 120% !important;
			}
			@include media-breakpoint-up(lg) {
				margin-top: 40px;
				font-size: 140px !important;
				line-height: 120% !important;
			}
		}
	}
}

.join-blunt-mag-archive {
	padding-top: 65px;
	padding-bottom: 35px;
	background-color: $dark-grey;
	color: $white;
	h3 {
		font-family: $Teko;
		font-style: normal;
		font-weight: bold;
		font-size: 42px;
		line-height: 50px;
	}
	p {
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;
		&.join-subtext {
			font-family: $Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 10px;
			line-height: 20px;
		}
	}
	input[type="text"] {
		background-color: transparent;
		border-bottom: 4px solid $light-gray;
		border-left: 0;
		border-right: 0;
		border-top: 0;
		margin-right: 20px;
		padding-left: 0;
		color: $light-gray;
	}
	.input-group-append {
		button {
			border: 4px solid $white;
			background-color: transparent;
			color: white;
			font-family: $Teko;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 1em;
		}
	}
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.archive-header-video {
	background-color: $black;
	padding-top: 10px;
	padding-bottom: 30px;

	.subtitle {
		font-family: $Teko;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 120%;
		color: $blunt-green;
	}
	h2 {
		font-family: $Plex;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		line-height: 38px;
		a {
			color: white;
		}

		@include media-breakpoint-up(lg) {
			padding-right: 80px;
		}
	}
	.cat-links {
		color: $gold;
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		a {
			color: $gold;
		}
	}
	.excerpt {
		color: $white;
	}
	.byline,
	.posted-on {
		color: $white;
		font-weight: 500;
		font-size: 12px;
		line-height: 20px;
		a {
			color: $white;
		}
	}
}

.tag-header {
	padding-top: 40px;
	padding-bottom: 30px;
	background-color: $black;
	color: $white;
	h1 {
		color: white;
		font-family: $Plex;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 30px;
		@include media-breakpoint-up(lg) {
			font-size: 65px;
			line-height: 60px;
		}
	}
	.subtitle {
		color: $blunt-green;
		font-family: $Teko;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 120%;
	}
	.cat-nav {
		flex-wrap: nowrap;
		a {
			&:first-of-type {
				padding-left: 0;
			}
			white-space: nowrap;
			padding-right: 30px;
			color: $blunt-green;
			font-family: $Inter;
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 105.4%;
			&.active,
			&:hover {
				color: $white;
			}
		}
	}
}

// single page template

.page-header-wrapper {
	padding-top: 60px;
	padding-bottom: 60px;
	margin-bottom: 60px;
	background-color: $black;
	color: $white;
	h1 {
		font-size: 40px;
		line-height: 30px;
		font-family: $Plex;
		font-style: normal;
		font-weight: bold;
		@include media-breakpoint-up(lg) {
			font-size: 65px;
			line-height: 65px;
		}
	}
}

// site widgets

.widget-area {
	.widget {
		.widget-title {
			text-transform: uppercase;
			font-family: $Teko;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 120%;
			margin-bottom: 20px;
		}
		margin-bottom: 60px;
	}
	.spotify-widget {
		background-color: white;
		padding: 20px;
		filter: drop-shadow(0px 4px 20px #37b6ab);
	}
	.insta-widget {
		background-color: white;
		filter: drop-shadow(0px 4px 20px #37b6ab);
		padding: 10px;
		#sbi_mod_error,
		#sbi_mod_link {
			margin-top: 0;
			float: none !important;
		}
	}
}

// latest widget

.trending-widget {
	.trending-widget-item {
		a {
			font-family: $Plex;
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 21px;
			color: $black;
			// min-height: 63px;
			&:hover {
				text-decoration: none;
			}
		}
		.count-tend {
			min-width: 26px;
			width: 26px;
			font-family: $Teko;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			line-height: 120%;
		}
	}
	img {
		width: 100%;
		height: auto;
	}
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 1px solid $gray;
}

//search-form

.search-form {
	input {
		&:focus {
			color: $blunt-green;
		}
		background-color: transparent !important;
		color: $blunt-green;
		border: 0px solid transparent;
		font-family: $Plex;

		font-weight: normal;
		font-size: 30px;
		line-height: 105.4%;
		font-style: normal;
		@include media-breakpoint-up(md) {
			font-weight: bold;
			font-size: 65px;
			line-height: 105.4%;
		}
		&::placeholder {
			color: #454444;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #454444;
		}

		&::-ms-input-placeholder {
			color: #454444;
		}
	}
	button {
		font-family: $Teko;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		// line-height: 120%;
		padding: 22px auto;
		&:hover {
			background-color: white;
			color: black;
		}
	}
}

// long-reads

body.long-reads {
	background-color: $dark-grey;
	.entry-content {
		position: relative;
		.sharing-floater {
			@include media-breakpoint-up(md) {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
			}
		}
		.fb-section {
			margin-bottom: 40px;
			@include media-breakpoint-up(md) {
				margin-bottom: 60px;
			}
			&:first-of-type {
				margin-bottom: 100px;
			}
			&.wordpress_editor {
				// p {
				// 	&:first-of-type {
				// 		&:first-letter {
				// 			-webkit-initial-letter: 2;
				// 			initial-letter: 2;
				// 			font-weight: 700;
				// 			float: left;
				// 			font-size: 400%;
				// 			margin-top: 18px;
				// 			margin-right: 15px;
				// 		}
				// 	}
				// }
			}
			&.intro_text {
				font-family: $Inter;
				font-style: normal;
				font-weight: 500;
				font-size: 30px;
				line-height: 45px;
			}
			p {
				font-size: 16px;
				&.cap {
					&:first-letter {
						-webkit-initial-letter: 2;
						initial-letter: 2;
						font-weight: 700;
						float: left;
						font-size: 400%;
						margin-top: 18px;
						margin-right: 15px;
					}
				}
			}
			blockquote {
				margin-left: 4px;
				padding: 20px 0px 20px 20px;
				border-left: 10px solid $blunt-green;
				p {
					margin-bottom: 0;
				}
			}
		}
	}
}
.long-reads-header {
	.row {
		height: 76px;
	}
	.site-branding {
		img {
			max-height: 15px;
			width: auto;
		}
	}
}
.long-reads-gutter {
	@include media-breakpoint-up(md) {
		padding-top: 40px;
		padding-left: 40px;
		padding-right: 40px;
	}
}
.image-carousel {
	.swiper-container-post {
		margin-right: -(1 * $grid-gutter-width);
		overflow-x: hidden;
		.swiper-slide {
			width: auto;
			// height: 400px;
			.swiper-post-inner {
				max-width: 336px;
				&.tall {
					max-width: 230px;
				}
				height: 280px;
				@include media-breakpoint-up(md) {
					height: 330px;
				}
				display: flex;
				align-items: flex-end;
				img {
					width: 100%;
					height: auto;
				}
			}
			.swiper-post-meta {
				max-width: 336px;
				&.tall {
					max-width: 230px;
				}
				// min-height: 100px;
				p {
					font-family: $Inter;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 20px;
				}
				.slide-title {
					@extend .mt-3;
					@extend .mb-0;
					color: $black;
				}
				.slide-caption {
					@extend .mt-0;
					@extend .mb-0;
					color: $gray;
				}
			}
		}
		.swiper-button-next {
			top: calc(100% + 30px);
			left: 40px;
			width: 20px;
			height: 20px;
			&:after {
				font-size: 20px;
				color: $black;
			}
		}
		.swiper-button-prev {
			&:after {
				font-size: 20px;
				color: $black;
			}
			bottom: 0;
			left: 00px;
			top: calc(100% + 30px);
			width: 20px;
			height: 20px;
		}
	}
}
.caro-col {
	margin-bottom: 60px;
}

.full-width-image {
	@include media-breakpoint-down(md) {
		figure {
			width: 100%;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.img-wrapper {
		margin-left: -($grid-gutter-width);
		margin-right: -($grid-gutter-width);
	}
}

.long-read-leader {
	.read-time {
		color: $gold;
		font-family: $Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 105.4%;
	}
	@include media-breakpoint-down(md) {
		background-color: $black;
	}
	position: relative;
	.leader-white {
		@include media-breakpoint-up(md) {
			position: absolute;
			left: 0;
			content: "";
			right: 0;
			bottom: 0;
			height: 60px;
		}
	}
	.subtitle {
		color: $white;
	}
	.image {
		margin-left: -(1 * ($grid-gutter-width * 0.5));
		margin-right: -(1 * ($grid-gutter-width * 0.5));
		@include media-breakpoint-up(md) {
			padding-left: 56px;
		}
		img {
			@include media-breakpoint-up(md) {
				background: linear-gradient(
						0deg,
						rgba(0, 0, 0, 0.13),
						rgba(0, 0, 0, 0.13)
					),
					url(92559395_1570074919840108_7380612487395147776_o-1024x731.jpg);
				box-shadow: 10px 10px 50px 20px #37b6ab;
			}
		}
	}
	.header-footer-details {
		@include media-breakpoint-up(md) {
			bottom: 85px !important;
		}
	}
	.entry-header {
		margin-bottom: 0;
		background-color: transparent;
	}
	.header-wrapper {
		background-color: transparent;
	}
	.header-details {
		@include media-breakpoint-down(md) {
			margin-top: -60px;
		}
		position: static !important;
		@include media-breakpoint-up(md) {
			position: absolute !important;
		}
	}
	.header-footer-details {
		position: static !important;
		@include media-breakpoint-up(md) {
			position: absolute !important;
		}
	}
}

.container {
	.spacer {
		height: 26px;
		@include media-breakpoint-up(md) {
			height: 100px;
		}
	}
}

.wrapper-long-reads {
	min-height: 100vh;
	padding-bottom: 0 !important;
}

.highlight_quote {
	position: relative;
	.background {
		position: absolute;
		left: 20px;
		right: 20px;
		@include media-breakpoint-up(md) {
			left: 70px;
			right: 70px;
		}
		@include media-breakpoint-up(lg) {
			left: 102px;
			right: 102px;
		}
		top: 0;
		bottom: 0;
		// background: #FFFFFF;
		div {
		}
		box-shadow: 0px 4px 50px 2px #37b6ab;
	}
	.quote {
		padding-top: 40px;
		padding-bottom: 40px;
		font-family: $Plex;
		font-style: italic;
		font-weight: 500;
		font-size: 32px;
		line-height: 45px;
		@include media-breakpoint-up(md) {
			font-size: 40px;
			line-height: 55px;
		}
	}
	.meta-author {
		margin-bottom: 0;
		font-family: $Plex;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 20px;
	}
	.meta-source {
		margin-bottom: 0;
		font-family: $Plex;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
	}
}
